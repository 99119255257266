import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import * as signalR from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from './base-service';
import { AuthService } from '@auth0/auth0-angular';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {

  private hubConnection: HubConnection;
  private messageSubject = new Subject<string>();

  constructor(http: HttpClient, private toastService: ToastrService, private userService: UsersService, private auth: AuthService) {
    super(http, `notifications`, toastService);

    this.hubConnection = new HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl([this.domain, 'notifications'].join('/'), {
            skipNegotiation: true,
        accessTokenFactory: () => {
          // Obtain and return the JWT token from your AuthService
          return firstValueFrom(this.auth.getAccessTokenSilently());
        },
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    this.hubConnection.start()
      .catch(err => console.error('Error while starting connection:', err));

    this.hubConnection.on('receivenotification', (message: any) => {
      this.messageSubject.next(message);
    });
  }

  sendMessage(message: string) {
    this.hubConnection.invoke('SendMessage', 'Angular', message)
      .catch(err => console.error('Error while sending message:', err));
  }

  receivenotification(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  navigate(entity: any, referenceId: any) {
    let url = '';

    this.userService.userRole$.subscribe(role => {
      if (role == "Admin") {
        if (entity == 'FormSubmission') {
          url = `/admin/providers-submission-details/${referenceId}`;
        }
        else if (entity == "FormConsolidation") {
          url = `/admin/providers-consolidation-details/${referenceId}`;
  
        }
        else {
          url = `/admin/lenders-mbr-request/${referenceId}`;
  
        }
      }
      else if (role == "Provider") {
        if (entity == 'FormSubmission') {
          url = `/provider/form-submissions-detail/${referenceId}`;
        }
        else {
          url = `/provider/form-consolidaton-detail/${referenceId}`;
        }
      }
      else {
        url = `/lender/mbr-request-detail/${referenceId}`;
      }
  
      return url;
    })
    
  }

}
