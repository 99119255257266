import { Component, Input } from '@angular/core';
import { FormSubmissionStatus } from 'src/app/services/form-submissions.service';

@Component({
  selector: 'app-form-status-list',
  template: ` <div class="root">
    <app-form-status
      *ngFor="let status of statuses | slice : 0 : MAX_STATUS"
      [status]="status"
      [showText]="false"
    ></app-form-status>
    <ng-container *ngIf="statuses.length > MAX_STATUS">
      <app-form-status
        [showText]="true"
        [status]="{
          id: 0,
          statusText: (this.statuses.length - this.MAX_STATUS).toString(),
          name: '',
          statusId: 1,
          waitingForCurrentUser: false
        }"
      />
    </ng-container>
  </div>`,
  styles: [
    `
      .root {
        display: flex;
        flex-direction: row;
      }
      app-form-status {
        margin-right: -12px;
        border: 3px solid white;
        border-radius: 50%;
      }
    `,
  ],
})
export class FormStatusListComponent {
  @Input() statuses: FormSubmissionStatus[] = [];
  readonly MAX_STATUS = 3;
}
