import { Component, Input } from '@angular/core';
import {
  FormSubmissionStatus,
  StatusId,
} from 'src/app/services/form-submissions.service';
import { MarketingMaterialStatus } from 'src/app/services/marketing-material.service';

@Component({
  selector: 'app-form-status',
  templateUrl: './form-status.component.html',
  styleUrls: ['./form-status.component.scss'],
})
export class FormStatusComponent {
  @Input() status: FormSubmissionStatus | MarketingMaterialStatus;
  @Input() showText: boolean = true;

  get icon(): string {
    switch (this.status.statusId) {
      case StatusId.Received:
      case StatusId.SentBack:
        return this.status.id == 0 ? 'add' : 'mail_outline';
      case StatusId.UnderReview:
        return 'hourglass_top'
      case StatusId.Approved:
        return 'check';
      case StatusId.Rejected:
        return 'block';
      default:
        return 'add';
    }
  }
  get color(): string {
    switch (this.status.statusId) {
      case StatusId.Received:
      case StatusId.SentBack:
        return '#2D3748';
      case StatusId.UnderReview:
        return '#102B6F';
      case StatusId.Approved:
        return '#1C4532';
      case StatusId.Rejected:
        return '#63171B';
      default:
        return '#102B6F';
    }
  }

  get backgroundColor(): string {
    switch (this.status.statusId) {
      case StatusId.Received:
      case StatusId.SentBack:
        return '#EDF2F7';
      case StatusId.UnderReview:
        return '#C9EAF8';
      case StatusId.Approved:
        return '#9AE6B4';
      case StatusId.Rejected:
        return '#FEB2B2';
      default:
        return '#C9EAF8';
    }
  }

  get borderColor(): string {
    switch (this.status.statusId) {
      case StatusId.Received:
      case StatusId.SentBack:
        return '#4A5568';
      case StatusId.UnderReview:
        return '#27AAE1';
      case StatusId.Approved:
        return '#38A169';
      case StatusId.Rejected:
        return '#E53E3E';
      default:
        return '#27AAE1';
    }
  }
}
