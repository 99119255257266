import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/services/base-service';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectDropdownComponent implements OnInit {

  private service: BaseService;
  dataSource: any[];
  settings: any;

  selectedItems: any[] = [];

  @ViewChild(MultiSelectComponent) dropdown: MultiSelectComponent;

  constructor(private httpClient: HttpClient, private toastService: ToastrService) {
  }
  
  ngOnInit(): void {

    this.settings = {
      singleSelection: false,
      idField: this.valueField,
      textField: this.displayField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.loadData();
    this.bindToControl();
  }

  public loadData(filters: any | null = null) {

    if (this.serviceUrl) {
      this.service = new BaseService(this.httpClient, this.serviceUrl, this.toastService);
      this.service.get(filters).subscribe(res => {
        if(!res.isError) {
          this.dataSource = res.data;
        }
      });
    }
  } 

  
  getFormControl(): FormControl {
    return this.control as FormControl;
  }

  public onClose() {
    this.updateControl();
  }

  public onSelect(event: any) {
    this.selectedItems.push(event);
    this.updateControl();
  }

  public onDeSelect(event: any) {
    this.selectedItems = this.selectedItems.filter(item => item.id !== event.id);
    this.updateControl();
  }

  public onSelectAll(event: any) {
    this.selectedItems = this.dataSource;
    this.updateControl();
  }

  public onDeSelectAll(event: any) {
    this.clearSelection();
    this.updateControl();
  }

  private updateControl() {
    this.control.markAsDirty();
    const items = this.selectedItems.map(x => x.id);
    if (items.length > 0) {
      this.control.setValue(items);
    }
    else {
      this.control.setValue('');
    }
  }

  private clearSelection(){
    this.selectedItems = []
  }

  private bindToControl(){
    this.control.valueChanges.subscribe(values => {
      if(!values)
      {
        this.selectedItems = [];
        return;
      }
      
      this.selectedItems = values.map((value: any) => this.dataSource.find(data => data.id === value))
    })
  }

  @Input() serviceUrl: string;
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() valueField: string = "id";
  @Input() displayField: string = "name";
  @Input() disabled: boolean;
}