import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, throwError } from 'rxjs';
import { ISearchResult } from './search.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  domain: any;
  baseUrl: string;

  private httpClient: HttpClient;

  constructor(private http: HttpClient, private toastService: ToastrService) {
    this.domain =
    environment.baseUrl ?? location.origin.replace('portal', 'api');
  this.baseUrl = [this.domain, 'api', 'search'].join('/');
  }

  generalSearch(search: any): Observable<ISearchResult[]> {
    return this.http.get<ISearchResult[]>(`${this.baseUrl}`, search ? { params: {key: search} } : { params: {} })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastService.error(
          'An error occurred while searching'
        );
        return throwError(() => new Error(error.message));
      })
    );
  }

}
