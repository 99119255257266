import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "./authentication.service";
import { Router } from "@angular/router";
import { UsersService } from "./users.service";

@Injectable({
  providedIn: 'root'
})

export class CustomNotificationService {

    constructor(private toastrService: ToastrService, private userService: UsersService, private router: Router) {

    }

    public showCustomNotification(data: any) {

      if (data.title == 'Approved') {
        let toast = this.toastrService.success(data.title, data.description, { payload: data});
        toast.onTap.subscribe(s => {
          toast.toastRef.componentInstance.options.payload;
        });

      }
      else if (data.title == 'Rejected') {
        let toast = this.toastrService.error(data.title, data.description, { payload: data});
        toast.onTap.subscribe(s => {
          toast.toastRef.componentInstance.options.payload;
        });

      }
      else {
        let toast = this.toastrService.info(data.title, data.description, { payload: data});
        toast.onTap.subscribe(s => {
          let entity = toast.toastRef.componentInstance.options.payload.entity;
          let id = toast.toastRef.componentInstance.options.payload.referenceId;

          this.navigate(entity, id);

        });
      }

      }

      navigate(entity: any, referenceId: any) {
        let url = '';

        this.userService.userRole$.subscribe(role => {
          if (role == "Admin") {
            if (entity == 'FormSubmission') {
              url = `/admin/providers-submission-details/${referenceId}`;
            }
            else if (entity == "FormConsolidation") {
              url = `/admin/providers-consolidation-details/${referenceId}`;
  
            }
            else {
              url = `/admin/lenders-mbr-request/${referenceId}`;
  
            }
          }
          else if (role == "Provider") {
            if (entity == 'FormSubmission') {
              url = `/provider/form-submissions-detail/${referenceId}`;
            }
            else {
              url = `/provider/form-consolidaton-detail/${referenceId}`;
            }
          }
          else {
            url = `/lender/mbr-request-detail/${referenceId}`;
          }
  
          this.router.navigate([url]);
        
        });
      }
}
