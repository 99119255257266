import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

export enum StatusId {
  'Received' = 1,
  'UnderReview' = 2,
  'SentBack' = 3,
  'Approved' = 5,
  'Rejected' = 6,
}
export interface FormSubmissionStatus {
  id: number;
  name: string;
  statusId: StatusId;
  statusText: string;
  waitingForCurrentUser: boolean;
}

export interface FormSubmission<
  UserType extends 'Lender' | 'Provider' | 'Admin' =
    | 'Lender'
    | 'Provider'
    | 'Admin'
> {
  formSubmissionStatus: UserType extends 'Lender'
    ? FormSubmissionStatus
    : FormSubmissionStatus[];
  id: number;
  industry: {
    id: 4;
    name: string;
  };
  intendedDateOfUse: string;
  product: {
    id: number;
    name: string;
  };
  submissionDate: string;
  submissionType: {
    id: number;
    name: string;
  };
  provider: {
    id: number;
    name: string;
  };
  states: {
    id: number;
    name: string;
  }[];
  lenders: {
    name: string;
    isApproved: boolean | null;
  }[];
  documents: [];
  formNo: string;
  previousApprovedFormNo: string;
  anotherAdministratorName: string;
  administrator: {
    id: number;
    name: string;
  };
  dueDate: string;
  providerFormNo: string;
  canTakeAction: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class FormSubmissionsService extends BaseService<FormSubmission> {
  constructor(http: HttpClient, private toastService: ToastrService) {
    super(http, `formsubmissions`, toastService);
  }

  uploadDocument(id: string, file: File): Observable<APIResponseModel<any>> {
    let formData = new FormData();
    formData.append(file.name, file);

    return this.http
      .post<APIResponseModel<any>>(
        `${this.baseUrl}/documentupload/${id}`,
        formData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getComments(id: any): Observable<APIResponseModel<any[]>> {
    return this.http
      .get<APIResponseModel<any[]>>(`${this.baseUrl}/comments/${id}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }

  updateStatus(
    id: string,
    action: string,
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/${action}/${id}`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  updateFormSubmission(
    id: string,
    data: any
  ): Observable<APIResponseModel<any>> {
    return this.http
      .put<APIResponseModel<any>>(`${this.baseUrl}/${id}`, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getLenders(formId: number) {
    return this.http
      .get<APIResponseModel<any[]>>(`${this.baseUrl}/lenders/${formId}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }


}
