<main>
    <app-main-navigation></app-main-navigation>
    <div class="left-sidebar" app-left-sidebar></div>
    <div class="main-container">
        <section class="main-section">
            <router-outlet></router-outlet>
        </section>
    </div>
</main>
<app-right-sidebar></app-right-sidebar>
