import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { debounceTime, distinctUntilChanged, filter, Observable, of, switchMap, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomNotificationService } from 'src/app/services/custom-notification.service';
import { NotificationService } from 'src/app/services/notifications.service';
import { SearchService } from './search.service';
import { ISearchItem, ISearchResult } from './search.model';
import { Role } from 'src/app/models/user/role.model';
import { TypeOfResult } from 'src/app/models/typeOfResult/typeOfResult';
import {MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {

  user$: Observable<User | null | undefined>;
  userRole: Role | undefined;
  userOrganization: string | undefined;
  notifications: any[] = [];

  searchControl = new FormControl('', [Validators.minLength(4)]);
  filteredOptions?: ISearchItem[];
  loading: boolean;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private authService: AuthenticationService,
    public notificationService: NotificationService,
    private customNotificationService: CustomNotificationService,
    private router: Router,
    private userService: UsersService,
    private searchService: SearchService
  ) {

    this.notificationService.receivenotification().subscribe((msg: any) => {
      this.customNotificationService.showCustomNotification(msg);
      this.notifications.unshift(msg);
    });

    this.user$ = authService.user$;

    this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => this.validateInput(value)),
      tap(() => this.loading = true),
      debounceTime(2000),
      switchMap(value => this._filter(value || '')),
      tap(() => this.loading = false),
    ).subscribe((options: any) => {
      this.filteredOptions = options.data.flatMap((optionGroup?: ISearchResult) => optionGroup?.items) as ISearchItem[];
    });
  }

  ngOnInit(): void {
    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
    });
  }

  onLogout() {
    this.authService.logout();
  }

  //Gets User object and returns firstName and lastName separate by " "
  getFullName(user: User | null | undefined): string | undefined {
    let fullName = '';
    if(user?.given_name && user.family_name) {
       fullName = `${user.given_name} ${user.family_name}`;
    }
    return fullName ? fullName : user?.nickname;
  }

  getMostRecentResultFor() {
    return this.searchControl.value
            && this.filteredOptions
            && this.filteredOptions?.length > 0
            || this.loading
              ? 'Most recent results for '
              : 'No results found for ';
  }

  private _filter(value: string): Observable<ISearchResult[]> {
    if(!value) return of([]);

    const filterValue = value.toLowerCase();

    return this.searchService.generalSearch(filterValue);
  }

  hasMinLength(value: any){
    return value ? value.length > 3 : false;
  }

  validateInput(value: any){
    if(this.hasMinLength(value)) {
      return true;
    }
    this.filteredOptions = []
    return false;
  }

  showOptionsPanel() {
    return this.hasMinLength(this.searchControl.value) && this.getSearchValue()
  }

  navigateItemTo(id?: number, typeOfResult?: TypeOfResult) {

    if(typeOfResult == TypeOfResult.FormSubmission) {
      this.onViewFormSubmission(id);
    }
    else if(typeOfResult == TypeOfResult.FormConsolidation) {
      this.onViewFormConsolidation(id);
    }
    else if(typeOfResult == TypeOfResult.MBRRequest) {
      this.onViewMBR(id);
    }
    else {
      this.onViewMarketingMaterial(id);
    }

  }

  onViewFormSubmission(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/form-submission/details/${id}`]);
    } else if (this.userRole === 'Lender') {
      this.router.navigate([`/lender/form-submission/details/${id}`]);
    } else {
      this.router.navigate([`/admin/form-submission/details/${id}`]);
    }
  }

  onViewMBR(id?: number) {
    if (this.userRole === Role.Lender) {
      this.router.navigate([`/lender/mbr-request/details/${id}`]);
    } else {
      this.router.navigate([`/admin/mbr-request/details/${id}`]);
    }
  }

  onViewFormConsolidation(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/form-consolidation/details/${id}`]);
    } else {
      this.router.navigate([`/admin/form-consolidation/details/${id}`]);
    }
  }

  onViewMarketingMaterial(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/marketing-material/details/${id}`]);
    } else if(this.userRole === 'Lender') {
      this.router.navigate([`/lender/marketing-material/details/${id}`]);
    }
    else {
      this.router.navigate([`/admin/marketing-material/details/${id}`]);
    }
  }
  goToSearchView() {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/search/list`], { queryParams: {key: this.searchControl.value}});
    }
    else if(this.userRole === Role.Lender) {
      this.router.navigate([`/lender/search/list`], { queryParams: {key: this.searchControl.value}});
    }
    else {
      this.router.navigate([`/admin/search/list`], { queryParams: {key: this.searchControl.value}});
    }
    this.closeAutocomplete()
  }

  getSearchValue() {
    return this.searchControl.value;
  }

  getResultTypeText(type?: TypeOfResult) {
    if(type === TypeOfResult.FormSubmission) {
      return 'Form Submission'
    }
    else if(type === TypeOfResult.FormConsolidation) {
      return 'Form Consolidation'
    }
    else if(type === TypeOfResult.MBRRequest) {
      return 'MBR Request'
    }
    else if(type === TypeOfResult.MarketingMaterial) {
      return 'Marketing Material'
    }
    return;
  }

  closeAutocomplete() {
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.closePanel();
    }
  }

}
