import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { APIResponseModel } from '../models/api-response-model';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseService {

  private apiUrl = `products`;
  private httpClient: HttpClient;

  constructor(http: HttpClient, private toastService: ToastrService) {
    super(http, `products`, toastService);
  }

  getByOrganizationId(filter: any): Observable<APIResponseModel<any>> {
    return this.http.get<APIResponseModel<any>>(`${this.baseUrl}/getlendersbyproductid`,filter ? { params: filter } : { params: {} })
    .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
    );
  }

  getByLenderId(filter: any): Observable<APIResponseModel<any>> {
    return this.http.get<APIResponseModel<any>>(`${this.baseUrl}/getproductsbylender`,filter ? { params: filter } : { params: {} })
    .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
    );
  }

}
