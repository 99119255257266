import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthenticationGuard } from './authentication.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full'  },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule), canActivate: [AuthenticationGuard], data: { roles: [ "Admin", "SystemUser"] }},
  { path: 'provider', loadChildren: () => import('./modules/provider/provider.module').then((m) => m.ProviderModule), canActivate: [AuthenticationGuard], data: { roles: [ "Provider"] } },
  { path: 'lender', loadChildren: () => import('./modules/lender/lender.module').then((m) => m.LenderModule), canActivate: [AuthenticationGuard], data: { roles: [ "Lender"] }  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
