<div
  class="root"
  [ngStyle]="{
    backgroundColor: backgroundColor,
    color: color,
    outline:
      '1.25px solid ' + borderColor,
  }"
  [ngClass]="{'extra-status' : status.id == 0}"
  [appTooltip]="showText ? status.name ? status.name : null : [status.name, status.statusText].join(' - ')"
>
  <span class="iconContainer">
    <span class="material-icons-outlined icon"
      >{{icon}}</span
    >
  </span>
  <span *ngIf="showText">{{ status.statusText }}</span>
</div>
