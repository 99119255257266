<div class="right-sidebar">
  <div class="right-sidebar-header">
    <h3 class="title">{{title}}</h3>
    <button class="close-button" mat-icon-button type="button" (click)="cancelClicked()">
      <mat-icon
        fontSet="material-icons-outlined"
        >close</mat-icon
      >
    </button>
  </div>
  <ng-content></ng-content>
  <div class="right-sidebar-footer">
    <button mat-stroked-button class="primary" (click)="cancelClicked()">
      Cancel
    </button>
    <button mat-flat-button
      color="primary"
      type="button"
      (click)="submitClicked()"
      [disabled]="disableSubmit"
    >
      {{ footerSubmitCustomLabel ? footerSubmitCustomLabel : 'Update'}}
    </button>
  </div>
</div>
