import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

@Injectable({
  providedIn: 'root'
})

export class ProvidersService extends BaseService {

  private apiUrl = `providers`;
  private httpClient: HttpClient;

  constructor(http: HttpClient, private toastService: ToastrService) {
    super(http, `providers`, toastService);
  }

  getByOrganizationId(id: string): Observable<APIResponseModel<any>> {
      return this.http.get<APIResponseModel<any>>(`${this.baseUrl}/organization/${id}`)
      .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.httpErrorHandler(err, {});
          })
      );
  }

}
