<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">Notifications</h3>
  </div>
  <div class="pro-card">
    <app-ag-grid-wrapper
      [data]="rowData$"
      [columns]="columnDefs"
      (ready)="onGridReady($event)"
    />
  </div>
</div>
