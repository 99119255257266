import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentModel, UPLOADSTATUS } from 'src/app/models/document/document.model';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent {


  constructor(private documentService: DocumentsService, private toastr: ToastrService) {

  }
  MAX_SIZE = 50000000; // 50MB

  @Input() disabled: boolean;
  @Input() isMulti: boolean = true;
  @Output() uploadingEvent = new EventEmitter<boolean>();
  @Output() onFileRemove = new EventEmitter<number>();

  public uploadedFiles() : any[] {
    return this.files.filter(file => file.uploadStatus === UPLOADSTATUS.SUCCESS).map((f) => ({ name: f.file.name, serverFileName: f.serverFileName }));
  }

  // Dropzone file uplaod funcitons
  @Input()files: DocumentModel[] = [];

  onSelect(event: any) {
    console.log(event);
    event.addedFiles.forEach((file: File) => {

      let documentDetails = new DocumentModel(file);

      this.documentService.uploadDocument(file).subscribe(res => {

        if (res.isError) {
          documentDetails.uploadStatus = UPLOADSTATUS.ERROR;
        }
        else {
          documentDetails.uploadStatus = UPLOADSTATUS.SUCCESS;
          documentDetails.serverFileName = res.data.fileName;
        }

        if(!this.files.some(d => d.uploadStatus === UPLOADSTATUS.PENDING)) {
          this.uploadingEvent.emit(false);
        }

      });

      this.files.push(documentDetails);
      this.uploadingEvent.emit(true);

    });

    if(event?.rejectedFiles?.length > 0) {
      this.toastr.error('Please upload a valid file: PDF, JPG, DOCX (max. 50MB)', 'Invalid File');
    }

  }

  public get uploadStatus(): typeof UPLOADSTATUS {
    return UPLOADSTATUS;
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.onFileRemove.emit(this.files.length);
  }

  // Getting uploaded file type to apply icon
  getFileType(fileType: any) {
    if (fileType) {
      var fileType = fileType.split('/');
      return (fileType = fileType[1]);
    }
    else {
      return 'general-file';
    }
  }

  public clearFiles() {
    this.files = [];
    this.onFileRemove.emit(this.files.length);
  }
}
