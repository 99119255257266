import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/services/base-service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {

  constructor(private authService: AuthenticationService, private documentService: DocumentsService) {
  }


  @Input() comment: any;

  downloadFile(file: any) {
    this.documentService.downloadDocument(file);
  }

  getDocType(file: any): string {
    return file.name.split('.')[file.name.split('.').length - 1];
  }
}