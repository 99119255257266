import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
// Ag Grid
import { AgGridModule } from 'ag-grid-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
// For file upload
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LenderModule } from './modules/lender/lender.module';
import { ProviderModule } from './modules/provider/provider.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BtnCellRenderer } from './components/btn-cell-renderer/btn-cell-renderer.component';
import { environment } from 'src/environments/environment';
import { TooltipModule } from './components/tooltip/tooltip.module';
import { AuthHttpInterceptor, AuthModule, provideAuth0 } from '@auth0/auth0-angular';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    BtnCellRenderer,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgGridModule,
    NgxDropzoneModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    LenderModule,
    ProviderModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), // ToastrModule added
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/login`,
        audience: environment.auth0.audience,
      },
      cacheLocation: 'memory',
      httpInterceptor: {
        allowedList: [{
          uri: `${environment.baseUrl}/*`,
          tokenOptions: {
            authorizationParams: {
              audience: environment.auth0.audience,
              scope: 'read:current_user'
            }
          }
        }]
      }
    })
  ],

  providers: [
    ...(environment.production
      ? [
        ]
      : []),
      { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
