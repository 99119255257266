import {
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, filter } from 'rxjs';
import { INavRoute } from './nav-routes';
import { NavRoutesService } from './nav-routes.service';
import { CommonModule } from '@angular/common';
import { NavItemComponent } from './nav-item/nav-item.component';
import { BypassHtmlSanitizerPipe } from '../../pipes/bypassHtmlSanitizer.pipe';
import { GeneralDialogComponent } from '../general-dialog/general-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { ToastrService } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'div[app-left-sidebar]',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    NavItemComponent,
    BypassHtmlSanitizerPipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  standalone: true,
})
export class LeftSidebarComponent {
  userNavRoutes$: Observable<INavRoute[]>;
  currentRoute: string;
  @ViewChild('templateReportContent')
  templateReportContent: TemplateRef<unknown>;
  @ViewChild('templateReportActions')
  templateReportActions: TemplateRef<unknown>;

  private reportDialogRef: MatDialogRef<GeneralDialogComponent | any>;

  templateReportContentPortal: TemplatePortal<unknown>;
  templateReportActionsPortal: TemplatePortal<unknown>;

  form = new FormGroup({
    title: new FormControl('', { validators: [Validators.required] }),
    description: new FormControl('', { validators: [Validators.required] }),
  });

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private navRoutesService: NavRoutesService,
    private reportProblemService: ReportProblemService,
    private _viewContainerRef: ViewContainerRef,
    private toastService: ToastrService,
    private auth: AuthService
  ) {
    this.userNavRoutes$ = navRoutesService.navRoutes$;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }

  ngAfterViewInit() {
    this.templateReportContentPortal = new TemplatePortal(
      this.templateReportContent,
      this._viewContainerRef
    );
    this.templateReportActionsPortal = new TemplatePortal(
      this.templateReportActions,
      this._viewContainerRef
    );
  }

  trackByFn(index: number, userNavRoute: INavRoute): number {
    return userNavRoute.id;
  }

  getCollapseId(route: INavRoute) {
    return route.children ? `collapse-${route.id}` : undefined;
  }

  mustActivateParent(parent: INavRoute) {
    return parent.children?.some((child) => child.path == this.currentRoute);
  }

  onLogout() {
    this.auth.logout();
  }

  openReportDialog() {
    this.reportDialogRef = this.dialog.open(GeneralDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: 'Report a Problem',
        content: this.templateReportContentPortal,
        actions: this.templateReportActionsPortal,
      },
    });
  }

  onSubmitReport() {
    let data = this.form.value;
    this.reportDialogRef.close();

    this.reportProblemService.reportProblem(data).subscribe((response) => {
      this.form.reset();
    });
  }
}
