export const environment: {
  baseUrl?: string;
  production: boolean;
  demo: boolean;
  auth0: {
    domain: string,
    clientId: string,
    audience: string
  };
} = {
  baseUrl: 'https://stg.portalapi.proteriscompliance.com',
  production: false,
  demo: true,
  auth0: {
    domain: 'stg.auth.portal.proteriscompliance.com',
    clientId: 'IXsVwsfJbMGTxfDAOoaUbiXeHySzj0wd',
    audience: 'https://stg-proteris-api/'
  }
};
