import { Component, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridReadyEvent } from 'ag-grid-community';
import { NotificationService } from 'src/app/services/notifications.service';
@Component({
  selector: 'app-mbr-request-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {

  isLoading: boolean = false;
  submissionService: any;

  constructor(private service: NotificationService) {

  }


 // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex:2,
      filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    {
      field: 'description', headerName: 'Description', flex:4, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    {
      field: 'action',
      width:150,
      filter: false,
      sortable:false,
      cellRenderer: (params: ICellRendererParams) => {
        return `<div class="btn-col">
        <a href="${this.service.navigate(params.data.entity, params.data.referenceId)}" class="btn btn-icon-fill-light"><i class="fal fa-eye"></i></a>
        </div>
        `
      }
    },
  ];

  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.service.get().subscribe(r => {
      this.rowData$ = r.data;
    });
  }
}
