import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InitialsPipe } from '../../../pipes/initials.pipe';
import { AvatarProfileComponent } from '../../avatar-profile/avatar-profile.component';
import { ImageRendererComponent } from '../../image-renderer/image-renderer.component';

@Component({
  selector: 'app-notification-menu-item',
  templateUrl: './notification-menu-item.html',
  styleUrls: ['./notification-menu-item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InitialsPipe, ImageRendererComponent, AvatarProfileComponent]
})
export class NotificationMenuItemComponent  {
  @Input() fullName: string;
  @Input() formId: string;
  @Input() date: string;
  @Input() time: string;

}
