import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportProblemService {
  domain: any;
  baseUrl: string;

  constructor(private http: HttpClient, private toastService: ToastrService) {
    this.domain =
      environment.baseUrl ?? location.origin.replace('portal', 'api');
    this.baseUrl = [this.domain, 'api', 'reportproblem'].join('/');
  }

  reportProblem(data: any): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}`, data, {
        observe: 'response',
      })
      .pipe(
        tap((response: any) => {
          if (response.status == 201) {
            this.toastService.success(
              'We will get back to you soon.',
              'Report sent!',
            );
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.toastService.error(
            'An error occurred while reporting the problem.'
          );
          return throwError(() => new Error(error.message));
        })
      );
  }
}
