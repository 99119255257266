<ag-grid-angular
  id="pcs-Grid"
  class="ag-theme-material"
  [cacheQuickFilter]="true"
  [rowData]="data"
  [columnDefs]="columns"
  [defaultColDef]="defaultCol"
  [animateRows]="true"
  [suppressCellFocus]="true"
  [loadingOverlayComponent]="loader"
  [loadingOverlayComponentParams]="loadingOverlayComponentParams"
  [paginationPageSize]="paginationPageSize"
  [paginationPageSizeSelector]="paginationPageSizeSelector"
  [localeText]="{
    pageSizeSelectorLabel: 'Show rows per page'
  }"
  [pagination]="true"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
