<nav class="navbar navbar-expand-sm" aria-label="">
  <div class="container-fluid px-24">
    <a class="company-name" [routerLink]="null">
      <app-image-renderer [imageName]="'PCS-icon.svg'" [altText]="'PCS-Icon'" />
    </a>
    <form class="search-form">
      <mat-form-field
        class="search-field custom-field no-hint"
        appearance="fill"
        [ngClass]="{ expanded: showOptionsPanel() }"
      >
        <input
          type="text"
          placeholder="Search"
          aria-label="Search"
          matInput
          [formControl]="searchControl"
          [matAutocomplete]="auto"
        />
        <mat-icon color="primary" matPrefix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option class="option-no-opacity" disabled *ngIf="showOptionsPanel()">
            <span
              >{{ getMostRecentResultFor()
              }}<strong>"{{ getSearchValue() }}"</strong></span
            >
          </mat-option>
          <mat-option
            *ngFor="let option of filteredOptions"
            [value]="option.systemNo"
            (click)="navigateItemTo(option.id, option.typeOfResult)"
          >
            <div class="item-result">
              <div class="item-name">
                <span class="material-icons-outlined icon-primary"
                  >insert_drive_file</span
                >
                {{ option.systemNo }}
              </div>
              <span class="item-type">{{ getResultTypeText(option.typeOfResult) }}</span>
            </div>
          </mat-option>
          <mat-option
            class="option-no-opacity bg-gray"
            disabled
            *ngIf="showOptionsPanel()"
          >
            <a
              (click)="goToSearchView()"
              class="footer-action"
              routerLinkActive="router-link-active"
              >View al results</a
            >
          </mat-option>
        </mat-autocomplete>
        <app-loader
          class="loader-container"
          *ngIf="loading"
          matSuffix
        ></app-loader>
      </mat-form-field>
      <mat-icon class="info-icon" matTooltip="Please enter at least 4 characters." matTooltipPosition="below">info_outlined</mat-icon>
    </form>
    <div class="d-flex" id="navbarsExample03">
      <div class="notifications-dropdown dropdown">
        <a
          href="javascript;"
          type="button"
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="material-icons-outlined notification-icon"
            >notifications_none</span
          >
          <span class="has-notifications"></span>
        </a>
        <div class="dropdown-menu">
          <div class="dropdown-header">
            <app-notification-menu-title [title]="'Notifications'" />
          </div>
          <ul class="custom-scroll">
            <!-- Mocked notification items start -->
            <li>
              <app-notification-menu-item
                [fullName]="'Joey Tribbiani'"
                [formId]="'IPGAP00001'"
                [date]="'12-22-2023'"
                [time]="'12:43'"
              />
            </li>
            <li>
              <app-notification-menu-item
                [fullName]="'Chandler Bing'"
                [formId]="'IPGAP00002'"
                [date]="'12-22-2023'"
                [time]="'12:43'"
              />
            </li>
            <!-- Mocked notification items end -->

            <!-- <li *ngFor="let item of notifications">
                <div class="drop-item">
                  <div class="icon">
                    <i class="fal fa-bell"></i>
                  </div>
                  <div class="drop-item-text">
                    <a [routerLink]="this.notificationService.navigate(item.entity, item.referenceId)">{{item.description}}</a>
                    <span>{{ item.dateTime }}</span>
                  </div>
                  <a [routerLink]="this.notificationService.navigate(item.entity, item.referenceId)" > <i class="fal fa-angle-right"></i></a>
                </div>
              </li> -->
          </ul>

          <div class="dropdown-footer">
            <a [routerLink]="'notifications-list'">View All Notifications</a>
          </div>
        </div>
      </div>

      <div class="user-dropdown dropdown">
        <a
          href="javascript;"
          type="button"
          class="dropdown-toggle user-a"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>
            {{ getFullName(user$ | async) | initials | uppercase }}
          </span>
        </a>
        <div class="dropdown-menu">
          <div class="dropdown-header">
            <app-avatar-profile
              [fullName]="getFullName(user$ | async)"
              [email]="(user$ | async)?.email"
              [config]="{ avatarSize: 'lg' }"
            />
          </div>
          <div class="h-separator"></div>
          <ul>
            <li>
              <a href="" class="user-menu-item">
                <span class="material-icons-outlined icon">lock</span>
                <span class="text">Update password</span>
              </a>
            </li>
            <li>
              <a href="" class="user-menu-item">
                <span class="material-icons-outlined icon">person</span>
                <span class="text">Edit account details</span>
              </a>
            </li>
            <li>
              <a href="" class="user-menu-item">
                <span class="material-icons-outlined icon">email</span>
                <span class="text">Email preferences</span>
              </a>
            </li>
            <li>
              <a href="" class="user-menu-item">
                <span class="material-icons-outlined icon"
                  >notifications_none</span
                >
                <span class="text">Notification preferences</span>
              </a>
            </li>
            <li>
              <a
                [routerLink]="null"
                class="user-menu-item"
                (click)="onLogout()"
              >
                <span class="material-icons-outlined icon">logout</span>
                <span class="text">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
