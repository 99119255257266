<div class="container">
    <div class="card parent">
      <div class="card-header">
        <h4>Notifications</h4>
      </div>
      <div class="card-body">
        <ag-grid-angular
            style="width: 100%; height: 500px"
            class="ag-theme-material"
            [cacheQuickFilter]="true"
            [rowData]="rowData$"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [animateRows]="true"
            [paginationPageSize]="paginationPageSize"
            [pagination]="true"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>
  </div>
