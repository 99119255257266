import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

@Injectable({
  providedIn: 'root'
})

export class DocumentsService extends BaseService {

  constructor(http: HttpClient, toastService: ToastrService) {
    super(http, `documents`, toastService);
  }

  uploadDocument(file: File): Observable<APIResponseModel<any>> {

    let formData = new FormData();
    formData.append("file", file);

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}`, formData)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  downloadDocument(file: any) {
    this.http.post(this.getDocumentUrl(file), {}, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    })
    .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.httpErrorHandler(err, {});
          })
        )
        .subscribe((res : any) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(res);
          a.download = file.name;
          a.click();
        });
  }
  

  getDocumentUrl(file: any): string {
    return `${this.baseUrl}/download/${file.serverFileName}`;
  }
}
